<template>
    <div>
        <div id='blog-content'>
            <div class="midContainer">
                <h1>学无止境</h1>
                <p class="introduce">
                    有幸遇见你，虽技术日异月新，但探索之心依然在，此刻，知音之间的技术交流因你我而精彩！
                </p>
                <el-row justify="center" type="flex">
                    <el-col :span="isPhoneClient == true ? 18 : 8">
                        <el-input placeholder="请输入内容" prefix-icon="el-icon-search" v-model="searchStr" size="default"
                            ref="searchContent">
                        </el-input>
                    </el-col>
                </el-row>
            </div>
            <div>
                <ul class="bottomContainer">
                    <li v-for="(blog,index) in blogFlitersItems" :key="index" class="blogItem">
                        <blog-item :blogItem="blog" :index=index :showSkeleton="showSkeleton"></blog-item>
                    </li>
                </ul>

            </div>
            <p class="more">
                <el-button round :loading="loading" @click="loadMore" v-if="!noMore">{{loadtext}}</el-button>
            </p>
            <el-empty description="哦豁暂时无内容" :image-size="400" v-if="isShowErrorTip"></el-empty>
            <p class="record"><a href="https://beian.miit.gov.cn" style="color: #bbb;"
                    target="_blank">粤ICP备2022057533号-1</a>
                <span class="text-color">©2022&nbsp;MambaYong&nbsp;</span></p>
        </div>
    </div>
</template>

<script>
    import blogItem from "views/BlogItem";
    import loadMoreBlogs from "network/loadMoreBlogs";
    import {
        BLOGIDARR
    } from "store/mutation-types";
    import {
        debounce
    } from "common/utils";
    export default {
        name: 'blogContent',
        data() {
            return {
                inputStyle: {},
                blogIdItemsArr: [],
                blogFlitersItems: [],
                searchStr: '',
                search: null,
                offset: 0,
                noMore: true,
                loading: false,
                loadMoreBlogs: null,
                isPhoneClient: false,
                loadtext: "点击加载更多",
                isShowErrorTip: false,
                category: "全部",
                showSkeleton: true,
                pageLength:50
            };
        },
        components: {
            blogItem,
        },
        methods: {
            doSearch() {
                if (this.searchStr) {
                    this.blogFlitersItems = this.blogFlitersItems.filter(item => {
                        return item.title.match(this.searchStr) || item.category.match(this.searchStr) || item
                            .digest.match(this.searchStr) || item.date.match(this.searchStr)
                    })

                } else {
                    this.blogFlitersItems = this.blogItemsArr;
                }

            },
            getFirstPage() {
                var params;
                if (this.category == "全部") {
                    params = {
                        limit: this.pageLength,
                        skip: 0
                    }
                } else {
                    params = {
                        limit: this.pageLength,
                        skip: 0,
                        where: {
                            "category": this.category
                        }
                    }
                }

                loadMoreBlogs(params).then(res => {
                    setTimeout(() => {
                        this.showSkeleton = false;
                    }, 500);
                    var length = res.results.length;
                    this.blogFlitersItems = [];
                    if (length) {
                        this.isShowErrorTip = false;
                        this.blogFlitersItems.push(...res.results);
                        this.blogIdItemsArr.push(...res.results.map((item) => item.objectId))
                        this.$store.commit(BLOGIDARR, this.blogIdItemsArr);
                        if (length >= this.pageLength) {
                            this.offset += this.pageLength;
                            this.noMore = false;
                        } else {
                            this.noMore = true;
                        }

                    } else {
                        this.blogFlitersItems = [];
                        this.isShowErrorTip = true;
                    }
                }).catch(err => {
                    this.showSkeleton = false;
                    this.blogFlitersItems = [];
                    this.isShowErrorTip = true;
                })
            },
            loadMore() {
                this.loading = true;
                this.loadtext = "加载中";
                setTimeout(() => {
                    var params;
                    if (this.category == "全部") {
                        params = {
                            limit: this.pageLength,
                            skip: this.offset,
                        }
                    } else {
                        params = {
                            limit: this.pageLength,
                            skip: this.offset,
                            where: {
                                "category": this.category
                            }
                        }
                    }
                    loadMoreBlogs(params).then(res => {
                        this.showSkeleton = false;
                        this.loading = false;
                        this.loadtext = "点击加载更多";
                        if (res.results.length >= this.pageLength) {
                            this.noMore = false;
                            this.offset += this.pageLength;
                        } else {
                            this.noMore = true;
                        }
                        this.blogFlitersItems.push(...res.results);
                        this.blogIdItemsArr.push(...res.results.map((item) => item.objectId))
                        this.$store.commit(BLOGIDARR, this.blogItemsArr);
                    }).catch(err => {
                        this.showSkeleton = false;
                        this.loading = false;
                        this.isShowErrorTip = true;
                    })

                }, 500);
            },
            watchWindowSize() {
                if (document.documentElement.clientWidth <= 750) {
                    this.isPhoneClient = true;
                } else {
                    this.isPhoneClient = false;
                }
            }
        },
        created() {
            this.getFirstPage();
        },
        mounted() {
            this.watchWindowSize();
            window.addEventListener("resize", this.watchWindowSize);
            this.search = debounce(this.doSearch, 300);
        },
        activated() {
            this.$bus.$on('filterContent', (category) => {
                this.showSkeleton = true;
                this.offset = 0;
                this.loadtext = "点击加载更多";
                this.category = category;
                this.noMore = true;
                this.getFirstPage();
            })

        },
        deactivated() {
            this.$bus.$off('filterContent')
        }
    }
</script>

<style lang='less' scoped>
    .midContainer {
        // margin: 0 auto; // 采用最简单的margin设置左右auto的方式来达到居中效果
        text-align: center;
        margin-bottom: 40px;
    }

    h1 {
        margin-top: 50px;
        font-size: 80px;
        font-family: Guillon, sans-serif;
        font-weight: 600;
    }

    .introduce {
        margin-top: 50px;
        margin-bottom: 20px;
        font-size: 18px;
        line-height: 1.5;
        padding: 0 50px;
    }

    svg {
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -16px;
        margin-left: 15px;
    }

    .input {
        color: -internal-light-dark(black, white);
        text-shadow: none;
        position: absolute;
        left: 0;
        right: 57px;
        top: 50%;
        height: 100%;
        margin-top: -24px;
        margin-left: 57px;
    }

    .bottomContainer {
        padding: 0 100px;
        // min-height: 900px;
        display: flex;
        display: -webkit-flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-content: flex-start;
    }

    .blogItem {
        flex: 0 31.33%;
        margin-left: 1%;
        margin-right: 1%;
        margin-bottom: 1%;

    }

    .record {
        position: fixed;
        background-color: white;
        color: #bbb;
        text-align: center;
        /* px-to-viewport-ignore-next */
        line-height: 30PX;
        bottom: 0;
        left: 0;
        right: 0;
        /* px-to-viewport-ignore-next */
        height: 30PX;
    }

    #blog-content {
        /* px-to-viewport-ignore-next */
        padding-bottom: 40PX;
        position: relative;
    }

    .more {
        text-align: center;
    }

    @media (max-width: 750px) {
        .search {
            height: 30px;
            width: 50vw;
            border: 1px solid #d4d6da;
            border-radius: 8px;
        }

        .record {
            height: 30px;
            line-height: 30px;
        }

        .more {
            margin-bottom: 30px;
        }

        .input {
            top: 50%;
            margin-top: -15px;
        }

        svg {
            display: none;
        }

        // 更改内容的flex布局
        .bottomContainer {
            flex-direction: column;
            flex-wrap: nowrap;
        }

        .blogItem {
            flex: 1;
            margin-left: 1%;
            margin-right: 1%;
        }

        .introduce {
            font-size: 14px;
        }

        h1 {
            margin-top: 20px;
            font-size: 20px;
            vertical-align: top;
        }


    }
</style>